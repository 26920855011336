<template>
  <div class="page-container">
    <div class="page-content">
      <div class="page-header">
        <div class="d-flex align-items-center mb-3">
          <h1 class="text-white" v-if="is_edit">
            <i class="mdi mdi-handshake"></i> Editar Tomador
          </h1>
          <h1 class="text-white" v-else>
            <i class="mdi mdi-handshake"></i> Novo Tomador
          </h1>
          <span @click="redirect()" class="btn btn-outline-white ml-auto"
            ><i class="fa fa-arrow-left mr-1"></i>
            <span class="d-none d-md-inline">Voltar</span></span
          >
        </div>
      </div>
      <div v-if="alertMessage" class="alert-custom alert-danger-custom">
        {{ alertMessage }}
      </div>
      <div
        v-if="loading"
        class="overlay d-flex align-items-center justify-content-center"
      >
        <div class="d-flex justify-content-center mb-2">
          <b-spinner label="Loading..."></b-spinner>
        </div>
      </div>
      <div class="page-body">
        <div class="card">
          <div class="card-body p-3 p-lg-5">
            <validation-observer
              ref="observer"
              v-slot="{ handleSubmit, invalid }"
            >
              <b-form
                @submit.stop.prevent="handleSubmit(saveEconomicGroup(true))"
                class="form"
                autocomplete="off"
              >
                <div class="col-12 mb-3">
                  <h5>
                    <div class="form-subheader">
                      <span>Dados do Tomador</span>
                    </div>
                  </h5>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="col-md-12 mt-2">
                      <validation-provider
                        name="Nome do grupo"
                        rules="required"
                        v-slot="validationContext"
                        vid="nome"
                      >
                        <label class="dbo-ui">GRUPO ECONÔMICO</label>
                        <b-form-input
                          v-model="dataFormCompany.group_name"
                          placeholder="Digite o nome do grupo"
                          :state="getValidationState(validationContext)"
                        >
                        </b-form-input>
                        <b-form-invalid-feedback>{{
                          validationContext.errors[0]
                        }}</b-form-invalid-feedback>
                      </validation-provider>
                    </div>
                    <div class="col-md-12">
                      <validation-provider
                        name="cnpj do Tomador"
                        rules="required|min:14"
                        v-slot="validationContext"
                        vid="cnpj"
                      >
                        <label class="dbo-ui">CNPJ</label>
                        <b-form-input
                          @change="onChangeCompanyCnpj"
                          v-model="dataFormCompany.cnpj"
                          v-mask="'##.###.###/####-##'"
                          placeholder="Digite o CNPJ da empresa"
                          :state="getValidationState(validationContext)"
                        >
                        </b-form-input>
                        <b-form-invalid-feedback>{{
                          validationContext.errors[0]
                        }}</b-form-invalid-feedback>
                      </validation-provider>
                    </div>
                    <div class="col-md-12 mt-2">
                      <validation-provider
                        name="razão social"
                        rules="required"
                        v-slot="validationContext"
                        vid="cnpj"
                      >
                        <label class="dbo-ui">Razão social</label>
                        <b-form-input
                          v-model="dataFormCompany.company_name"
                          placeholder="Digite o nome da empresa"
                          :state="getValidationState(validationContext)"
                        >
                        </b-form-input>
                        <b-form-invalid-feedback>{{
                          validationContext.errors[0]
                        }}</b-form-invalid-feedback>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="border-left col-lg-6 overflow-hidden">
                    <div class="row ml-2">
                      <div class="col-md-6">
                        <label class="dbo-ui">GRUPO ECONÔMICO</label>
                        <div class="d-flex">
                          <b-form-radio
                            v-model="selected_option"
                            value="true"
                            @change="updateSelectedOption(true)"
                            >Sim</b-form-radio
                          >
                          <b-form-radio
                            v-model="selected_option"
                            value="false"
                            class="ml-3"
                            @change="updateSelectedOption(false)"
                            >Não</b-form-radio
                          >
                        </div>
                      </div>

                      <div class="col-md-6">
                        <label class="dbo-ui">Fatura única</label>
                        <div class="d-flex">
                          <b-form-radio
                            v-model="single_invoice"
                            value="1"
                            >Sim</b-form-radio>
                          <b-form-radio
                            v-model="single_invoice"
                            value="0"
                            class="ml-3"
                            >Não</b-form-radio>
                        </div>
                      </div>

                      <div v-if="selected_option" class="col-md-12 mt-4">
                        <a
                          @click="$bvModal.show('show-modal-company')"
                          class="button btn btn-outline-secondary btn-sm float-right mb-2"
                        >
                          <i class="fa fa-plus mr-1"></i>
                          Adicionar nova empresa
                        </a>
                        <b-table
                          class="table pretty-title-gray mb-0"
                          :items="dataFormCompany.items_taker"
                          :fields="fieldsTaker"
                        >
                        <template #cell(company_name)="data">
                            <span class="centered-cell">{{data.value}}</span>
                          </template>
                          <template #cell(cnpj)="data">
                            <span class="centered-cell">{{data.value}}</span>
                          </template>
                          <template #cell(type)="data">
                            <span class="centered-cell" v-if="data.value == 1">Coligada</span>
                            <span class="centered-cell" v-if="data.value == 2">Filial</span>
                            <span class="centered-cell" v-if="data.value == 3">Controlada</span>
                            <span class="centered-cell" v-if="data.value == 4">Controladora</span>
                          </template>
                          <template #cell(actions)="row">
                            <div class="centered-header">
                              <a class="text-secondary" @click="deleteCompany(row)">
                                <i class="fas fa-times mx-1 icon-btn"></i>
                              </a>
                              <a class="text-secondary" @click="showCompany(row)">
                                <i class="fas fa-pen mx-1 icon-btn"></i>
                              </a>
                            </div>
                          </template>
                        </b-table>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 mt-5">
                  <h6>
                    <div class="form-subheader">
                      <span
                        >DOCUMENTOS <i class="fa fa-angle-right mx-2"></i>
                        <p class="text-dark my-0" style="overflow-wrap: normal">
                          Demonstrativos financeiros
                        </p>
                        <i class="fa fa-asterisk text-warning ml-1"></i
                      ></span>
                    </div>
                  </h6>
                  <div class="row mt-2">
                    <div class="col-md-12 mb-3">
                      <b-tabs>
                        <b-tab
                          v-for="balanceType in balanceTypes"
                          :key="balanceType"
                          :title="getTabTitle(balanceType)"
                          :value="balanceType"
                          class="nav-item"
                        >
                          <b-card-text
                            ><div
                              class="border-left border-bottom border-right p-3 tab-content"
                            >
                              <upload-data
                                :files="
                                  files[
                                   isAcceptedDocumentType(balanceType)
                                      ? balanceType
                                      : balanceType.substring(8)
                                  ]
                                "
                                :url="urlUpload"
                                :forceRedirect="true"
                                @deleteFile="deleteFile"
                                @uploadFinished="uploadFinished"
                                @showEconomicGroupFiles="showEconomicGroupFiles"
                                :balanceType="balanceType"
                                ref="upload"
                              /></div
                          ></b-card-text>
                        </b-tab>
                      </b-tabs>
                      <observations-table
                        class="mt-4"
                        :observation_data="observation_data"
                        :items_observation="dataFormCompany.items_observation"
                        @setObservationItems="setObservationItems"
                        @showClientData="showClientData"
                      />
                    </div>
                    <p class="mt-3 ml-2">
                      <i class="fa fa-asterisk text-warning ml-1"></i>
                      Todos os Balanços e Balancetes devem estar com DRE.
                    </p>
                    <div class="col-md-12 mb-3">
                      <h5>
                        <div class="form-subheader">
                          <span>RELAÇÃO DE CAPACIDADE NO MERCADO </span>
                        </div>
                      </h5>
                      <div class="row mb-4">
                        <div class="col-md-12 mb-3">
                          <div
                            class="d-flex mb-3 align-items-end justify-content-between"
                          >
                            <label
                              class="dbo-ui mb-lg-0 font-weight-bold ml-2"
                              for=""
                              >SEGURADORAS
                            </label>
                            <a
                              @click="$bvModal.show('show-modal-insurer')"
                              class="button btn btn-outline-secondary btn-sm"
                            >
                              <i class="fa fa-plus mr-1"></i>
                              Adicionar seguradora
                            </a>
                          </div>
                          <div>
                            <b-table
                              class="table pretty-title-gray mb-0"
                              :items="dataFormCompany.items_insurer"
                              :fields="fields"
                            >
                              <template #cell(name)="data">
                                <span class="centered-cell">{{
                                  data.value
                                }}</span>
                              </template>
                               <template #cell(status)="data">
                                <span
                                      class="btn btn-sm btn-success text-white text-uppercase rounded-pill nowrap status-badge"
                                      v-if="data.value == 1"
                                    >
                                      Aprovado
                                    </span>
                                    <span
                                      class="btn btn-sm btn-orange text-white text-uppercase rounded-pill nowrap status-badge"
                                      v-if="data.value == 2"
                                    >
                                      Em Análise
                                    </span>
                                    <span
                                      class="btn btn-sm btn-orange text-white text-uppercase rounded-pill nowrap status-badge-documentation"
                                      v-if="data.value == 3"
                                    >
                                      Documentação pendente
                                    </span>
                                    <span
                                      class="btn btn-sm btn-danger text-white text-uppercase rounded-pill nowrap status-badge"
                                      v-if="data.value == 4"
                                    >
                                      Recusada
                                    </span>
                              </template>
                              <template #cell(approved_limit)="data">
                                <span class="centered-cell">{{
                                  formatCurrency(data.value) ?? ""
                                }}</span>
                              </template>
                              <template #cell(available_limit)="data">
                                <span class="centered-cell">{{
                                  formatCurrency(data.value) ?? ""
                                }}</span>
                              </template>
                              <template #cell(total_accumulation)="data">
                                <span class="centered-cell">{{
                                  formatCurrency(data.value) ?? ""
                                }}</span>
                              </template>
                              <template #cell(modality)="data">
                               <div class="centered-cell">
                                  <span
                                    class="nowrap col-md-10"
                                    v-if="data.value == 1"
                                  >
                                    Judicial
                                  </span>
                                  <span
                                    class="nowrap col-md-10"
                                    v-else
                                    >Tradicional</span
                                  >
                                </div>
                              </template>
                              <template #cell(current_rate)="data">
                                <span class="centered-cell">{{
                                  formatPercentage(data.value) ?? ""
                                }}</span>
                              </template>
                              <template #cell(validity)="data">
                                <span class="centered-cell">{{
                                 data.value != "" ? formatDateLocal(data.value) : "- - -"
                                }}</span>
                              </template>
                              <template #cell(ccg)="data">
                                <div class="centered-cell">
                                  <span
                                    class="btn btn-sm btn-success text-white text-uppercase rounded-pill nowrap col-md-10"
                                    v-if="data.value == 1"
                                  >
                                    regularizado
                                  </span>
                                  <span
                                    class="btn btn-sm btn-orange text-white text-uppercase rounded-pill nowrap col-md-10"
                                    v-else
                                    >Não regularizado</span
                                  >
                                </div>
                              </template>
                              <template #cell(appointment)="data">
                                <div class="centered-cell">
                                  <span
                                    class="btn btn-sm btn-success text-white text-uppercase rounded-pill nowrap col-md-10"
                                    v-if="data.value == 1"
                                  >
                                    Sim
                                  </span>
                                  <span
                                    class="btn btn-sm btn-danger text-white text-uppercase rounded-pill nowrap col-md-10"
                                    v-else
                                  >
                                    Não
                                  </span>
                                </div>
                              </template>
                              <template #cell(action)="row">
                                <a class="text-secondary" @click="deleteInsurer(row)">
                                  <i class="fas fa-times mx-1 icon-btn"></i>
                                </a>
                                <a class="text-secondary" @click="showInsurer(row.index)">
                                  <i class="fas fa-pen mx-1 icon-btn"></i>
                                </a>
                              </template>
                            </b-table>
                          </div>
                          <div class="col-lg-12">
                            <div class="float-right mr-3 mt-4">
                              <button
                                class="btn btn-outline-secondary mr-3"
                                v-if="!is_edit"
                                @click="redirect()"
                              >
                                <i class="fa fa-times mr-1"></i>
                                Cancelar
                              </button>
                              <button
                                v-if="is_edit"
                                :disabled="invalid"
                                class="button btn btn-primary mt-4"
                                type="submit"
                              >
                                Atualizar Tomador
                                <i class="fa fa-angle-right ml-2"></i>
                              </button>
                              <button
                                v-else
                                :disabled="invalid"
                                class="button btn btn-primary"
                                type="submit"
                              >
                                Cadastrar Tomador
                                <i class="fa fa-angle-right ml-2"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </b-form>
            </validation-observer>
          </div>
        </div>
      </div>
    </div>
    <economic-group
      @setEconomicGroup="setEconomicGroup"
      :economicGroup="dataFormEconomicGroup"
    />
    <insurer-modal
      @setInsurerData="setInsurerData"
      :dataFormInsurer="dataFormInsurer"
    />
    <observations-modal
      @setObservationData="setObservationData"
      @showClientData="showClientData"
    />
  </div>
</template>

  <script>
import { EconomicGroup, InsurerModal, UploadData, ObservationsModal, ObservationsTable } from "@/components/Client";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import commonsService from "@/services/commons";
import commomsHelperMixin from "@/mixins/commomsHelper";
import numericFormatterMixin from "@/mixins/numericFormatter";
import dateFormatterMixin from "@/mixins/dateFormatter";
import companyService from "@/services/company";
import { mask } from "vue-the-mask";
import balanceTypes from "@/enums/balanceTypes";
import balanceTypesTranslate from "@/enums/translate/balanceTypes";

export default {
  name: "client-store",
  components: {
    EconomicGroup,
    InsurerModal,
    UploadData,
    ValidationObserver,
    ValidationProvider,
    ObservationsModal,
    ObservationsTable,
  },
  mixins: [commomsHelperMixin, numericFormatterMixin, dateFormatterMixin],
  data: () => ({
    dataFormCompany: {
      group_name: "",
      cnpj: "",
      company_name: "",
      address: "",
      items_taker: [],
      items_insurer: [],
      items_observation: []
    },
    selected_option: false,
    single_invoice: 0,
    dataFormEconomicGroup: {
      cnpj: "",
      company_name: "",
      type: 1,
      address: "",
      index: null,
    },
    dataFormInsurer: {
      name: "",
      approved_limit: null,
      available_limit: null,
      total_accumulation: null,
      current_rate: null,
      validity: "",
      ccg: 1,
      appointment: 1,
      status: 0,
      modality: 1
    },
    is_edit: false,
    loading: false,
    urlUpload: "",
    balanceTypes: balanceTypes,
    balanceTypesTranslate: balanceTypesTranslate,
    files: [],
    balance_type: '',
    first_upload: true,
    observation_data: [],
  }),
  directives: {
    mask,
  },
  computed: {
    rows() {
      return this.dataFormCompany.items_insurer.length;
    },
    fields() {
      var columns = [
        {
          key: "name",
          label: "NOME",
          tdClass: "font-14",
          thClass: "table-pretty dbo-ui rounded-top centered-header",
        },
         {
          key: "status",
          label: "Status",
          tdClass: "font-14",
          thClass: "table-pretty dbo-ui centered-header",
        },
        {
          key: "approved_limit",
          label: "LIMITE APROVADO",
          tdClass: "font-14",
          thClass: "table-pretty dbo-ui centered-header",
        },
        {
          key: "available_limit",
          label: "LIMITE DISPONÍVEL",
          tdClass: "font-14",
          thClass: "table-pretty dbo-ui centered-header",
        },
        {
          key: "total_accumulation",
          label: "ACÚMULO TOTAL",
          tdClass: "font-14",
          thClass: "table-pretty dbo-ui centered-header",
        },
        {
          key: "modality",
          label: "Modalidade",
          tdClass: "font-14",
          thClass: "table-pretty dbo-ui centered-header",
        },
        {
          key: "current_rate",
          label: "TAXA ATUAL",
          tdClass: "font-14",
          thClass: "table-pretty dbo-ui centered-header",
        },
        {
          key: "validity",
          label: "VALIDADE",
          tdClass: "font-14",
          thClass: "table-pretty dbo-ui centered-header",
        },
        {
          key: "ccg",
          label: "CCG",
          tdClass: "font-14",
          thClass: "table-pretty dbo-ui centered-header",
        },
        {
          key: "appointment",
          label: "NOMEAÇÃO",
          tdClass: "font-14",
          thClass: "table-pretty dbo-ui centered-header",
        },
        {
          key: "action",
          label: "",
          tdClass: "font-14",
          thClass: "table-pretty dbo-ui rounded-top centered-header",
        },
      ];
      return columns;
    },
    rowsTaker() {
      return this.dataFormCompany.items_taker.length;
    },
    fieldsTaker() {
      var columns = [
        {
          key: "cnpj",
          label: "CNPJ",
          tdClass: "font-6",
          thClass: "table-pretty dbo-ui centered-header",
        },
        {
          key: "company_name",
          label: "NOME",
          tdClass: "font-6",
          thClass: "table-pretty dbo-ui centered-header",
        },
        {
          key: "type",
          label: "TIPO",
          tdClass: "font-6",
          thClass: "table-pretty dbo-ui centered-header",
        },
        {
          key: "actions",
          label: "",
          tdClass: "font-14 ",
          thClass: "table-pretty dbo-ui centered-header",
        },
      ];
      return columns;
    },
  },
  created() {
    if (this.getIdFromUrl().length == 36) {
      this.is_edit = true;
      this.showClientData();
      this.showEconomicGroupFiles();
    }
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    updateSelectedOption(value) {
      this.selected_option = value;
    },
    redirect() {
      this.$router.push({
        name: "list-client",
      });
    },
    redirectToClient(uid) {
      this.is_edit = true;
      this.$router.push({
        name: "update-client",
        params: { economicGroupID: uid }
      });
    },
    onChangeCompanyCnpj: function (cnpj) {
      if (cnpj.length == 18) {
        this.loading = true;
        cnpj = this.traitCnpj(cnpj);
        commonsService
          .findCnpj(cnpj)
          .then((response) => {
            if (response.data.error) {
              return;
            }
            this.dataFormCompany.company_name = response.data.name;
            this.dataFormCompany.address = response.data.address;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    showClientData(uid = null) {
      uid = uid != null ? uid : this.getIdFromUrl();
      this.loading = true;
      companyService
        .showEconomicGroup(uid)
        .then((response) => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }
          const { company, economicGroups, economicGroupInsurers, economicGroupObservations} = response.data;
          this.dataFormCompany = {
            items_taker: this.mapData(economicGroups),
            items_insurer: economicGroupInsurers,
            items_observation: economicGroupObservations
          };
          this.dataFormCompany.cnpj = company.economic_group_cnpj;
          this.dataFormCompany.group_name = company.group_name;
          this.dataFormCompany.company_name = company.company_name;
          this.dataFormCompany.address = company.address;
          this.single_invoice = company.single_invoice;
          this.selected_option = this.dataFormCompany.items_taker.length > 0;
        })
        .catch((error) => {
          this.showError(error);
        })
        .finally(() => {
            this.loading = false;
        });
    },
    mapData(data) {
      return data.map((group) => {
        return {
          cnpj: group.company_cnpj,
          company_name: group.company_name,
          type: group.economic_group_type_id,
          index: group.id
        };
      });
    },
    saveEconomicGroup(mustRedirect = false) {
      if (this.is_edit) {
        this.updateEconomicGroup(mustRedirect);
      } else {
        this.storeCompany(mustRedirect);
      }
    },
    storeCompany(mustRedirect = false) {
        this.dataFormCompany.selected_option = this.selected_option;
        this.dataFormCompany.single_invoice = this.single_invoice;
        this.loading = true;
        companyService
        .storeCompanyGroup(this.dataFormCompany)
        .then((response) => {
          if (response.data.error) {
            return;
          }
          this.is_edit ? this.showClientData() : this.saveFiles(response.data);
          if (mustRedirect) {
            this.$nextTick(() => {
              this.redirect();
            });
          }
        })
        .catch(() => {
          this.showError("Não foi possível criar ou atualizar dados do Tomador.");
        })
        .finally(() => {
          this.loading = false;
        });
      },
     updateEconomicGroup(mustRedirect = false) {
      this.dataFormCompany.selected_option = this.selected_option;
      this.dataFormCompany.single_invoice = this.single_invoice;
      this.loading = true;
      companyService
        .updateEconomicGroup(this.getIdFromUrl(), this.dataFormCompany)
        .then((response) => {
          if (response.data.error) {
            return;
          }
          this.showClientData();
          if(mustRedirect){
            this.redirect();
          }
        })
        .catch(() => {
          this.showError(
            "Não foi possível criar ou atualizar dados do Tomador."
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
    setEconomicGroup(data) {
      if (data.index != null) {
        this.updateCompany(data);
      } else {
        this.addNewCompany(data);
      }
    },
    addNewCompany(data) {
      if (
        !this.dataFormCompany.items_taker.some(
          (Object) => Object.cnpj === data.cnpj
        )
      ) {
        this.dataFormCompany.items_taker.push({
          company_name: data.company_name,
          cnpj: data.cnpj,
          type: data.type,
          address: data.address,
        });
      }
    },
    showCompany(data) {
      this.dataFormEconomicGroup = {
        ...this.dataFormCompany.items_taker[data.index],
      };
      this.dataFormEconomicGroup.index = data.index;
      this.dataFormEconomicGroup.old_cnpj = this.dataFormEconomicGroup.cnpj;
      this.$bvModal.show("show-modal-company");
    },
    updateCompany(data) {
      if (this.is_edit) {
        this.loading = true;
        companyService
          .updateGroup(this.getIdFromUrl(), data)
          .then((response) => {
            if (response.data.error) {
              return;
            }
          })
          .catch(() => {
            this.showError("Não foi possível criar ou atualizar compania do grupo econômico.");
          })
          .finally(() => {
            this.showClientData();
          });
      } else {
        Object.assign(this.dataFormCompany.items_taker[data.index], data);
      }
    },
    deleteCompany(data) {
      if (this.is_edit) {
        this.loading = true;
        companyService
          .deleteCompany(data.item.index)
          .then((response) => {
            if (response.data.error) {
              return;
            }
          })
          .catch(() => {
            this.showError("Não foi possível apagar compania do grupo economico.");
          })
          .finally(() => {
            this.showClientData();
          });
      } else {
        this.dataFormCompany.items_taker.splice(data.index, 1);
      }
    },
    setInsurerData(data) {
      if (data.index != null) {
        this.updateInsurer(data);
      } else {
        this.addNewInsurer(data);
      }
    },
    setObservationData(data) {
        this.observation_data = data;
    },
    setObservationItems(data) {
        this.dataFormCompany.items_observation = data;
    },
    addNewInsurer(data) {
      if (data.batch_registration) {
        companyService
          .updateItemsInsurer(this.getIdFromUrl(), data)
          .then((response) => {
            if (response.data.error) {
              return;
            }
          })
          .catch(() => {
            this.showError("Não foi possível atualizar seguradora do grupo economico.");
          })
          .finally(() => {
            this.showClientData();
          });
      } else {
        if (
          !this.dataFormCompany.items_insurer.some(
            (Object) => Object.name === data.name
          )
        ) {
          this.dataFormCompany.items_insurer.push({
            name: data.name,
            approved_limit: data.approved_limit,
            available_limit: data.available_limit,
            total_accumulation: data.total_accumulation,
            current_rate: data.current_rate,
            validity: data.validity,
            ccg: data.ccg,
            status: data.status,
            modality: data.modality,
            appointment: data.appointment,
          });
        }
      }
    },
    showInsurer(index) {
      this.dataFormInsurer = {
        ...this.dataFormCompany.items_insurer[index],
      };
      this.dataFormInsurer.index = index;
      this.$bvModal.show("show-modal-insurer");
    },
    updateInsurer(data) {
      if (this.is_edit) {
        this.loading = true;
        companyService
          .updateInsurer(data.id, data)
          .then((response) => {
            if (response.data.error) {
              return;
            }
          })
          .catch(() => {
            this.showError("Não foi possível atualizar seguradora do grupo economico.");
          })
          .finally(() => {
            this.showClientData();
          });
      } else {
        Object.assign(this.dataFormCompany.items_insurer[data.index], data);
      }
    },
    deleteInsurer(data) {
      if (this.is_edit) {
        this.loading = true;
        companyService
          .deleteInsurer(data.item.id)
          .then((response) => {
            if (response.data.error) {
              return;
            }
          })
          .catch(() => {
            this.showError("Não foi possível apagar seguradora do grupo economico.");
          })
          .finally(() => {
            this.showClientData();
          });
      } else {
        this.dataFormCompany.items_insurer.splice(data.index, 1);
      }
    },
    showEconomicGroupFiles() {
      this.loading = true;
      companyService
        .showEconomicGroupFiles(this.getIdFromUrl())
        .then((response) => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }
          this.clearUploadState();
          this.files = [];
          this.files = response.data;
        })
        .catch(() => {
          this.showError("Não foi possível apagar os grupos economicos.");
        }).finally(() => {
          this.loading = false;
        });
    },
   uploadFinished(balance_type) {
      this.balance_type = balance_type;
      if(!this.is_edit && this.first_upload) {
        this.first_upload = false
        this.storeCompany();
      }
      if(this.is_edit) {
        this.saveFiles(this.getIdFromUrl());
      }
    },
    saveFiles(uid) {
      this.urlUpload = `/api/v1/economic-group-file/${uid}/files/${this.balance_type}`;
      this.$nextTick(() => {
        this.$refs.upload.forEach((element) => {
          if (element.$refs.dropzone.getAcceptedFiles().length > 0) {
            element.$refs.dropzone.processQueue();
            return;
          }
        });
      });
      this.is_edit ? '' : this.redirectToClient(uid);
      this.showClientData(uid);
    },
    deleteFile(fileId) {
      if (this.is_edit) {
        this.loading = true;
        companyService
          .deleteEconomicGroupFile(fileId)
          .then((response) => {
            if (response.data.error) {
              this.showError(response.data.message);
              return;
            }
          })
          .catch(() => {
            this.showError("Erro ao apagar o arquivo do grupos economico.");
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    getTabTitle(balanceType) {
      if (this.isAcceptedDocumentType(balanceType)) {
        return this.getEnumKeyByEnumValue(this.balanceTypesTranslate, balanceType);
      }
      return "Balanço " + balanceType.substring(8);
    },
    isAcceptedDocumentType(balanceType){
      return balanceType == "CORPORATE-DOCUMENTATION" ||
             balanceType == "APPOINTMENT" ||
             balanceType == "OTHERS";
    },
    clearUploadState(){
      const keys = Object.keys(balanceTypes);
        for (let i = 0; i < keys.length; i++) {
          this.$refs.upload[i].clearUploadState();
        }
    },
  },
};
</script>

<style>
</style>
