import http from "@/services/http";

const get = (params) => { return http.get("companies", { params: params }) }
const getByCNPJ = (id) => http.get(`companies/${id}`)
const store = (company) => http.post('companies', company)
const update = (id, company) => http.put(`companies/${id}`, company)
const destroy = (uid) => http.delete(`companies/${uid}`)
const newCompany = (company) => http.post('companies/new-company', company)
const storeCompanyGroup = (company) => http.post('companies/store-company-group', company)
const updateEconomicGroup = (id, company) => http.put(`companies/store-company-group/${id}`, company)
const getEconomicGroups = (params) => http.get("economic-groups/list", { params: params })
const showEconomicGroup = (id) => http.get(`economic-groups/${id}`)
const deleteEconomicGroup = (id) => http.delete(`economic-groups/${id}`)
const deleteCompany = (cnpj) => http.delete(`economic-group/${cnpj}`)
const deleteInsurer = (id) => http.delete(`economic-group-insurer/${id}`)
const updateGroup = (id, company) => http.post(`economic-group/${id}`, company)
const updateInsurer = (id, insurer) => http.post(`economic-group/${id}/insurer`, insurer)
const showEconomicGroupFiles = (cnpj) => http.get(`economic-group-file/${cnpj}/files`)
const downloadEconomicGroupFile = (id) => http.get(`economic-group-file/${id}`)
const deleteEconomicGroupFile = (id) => http.delete(`economic-group-file/${id}`)
const getInsurerStatus = () => { return http.get("insurer-status") }
const downloadEconomicGroupZipFile = (id) => http.get(`economic-group-file/${id}/download-zip`, { responseType: 'blob' })
const deleteEconomicGroupObservation = (id) => http.delete(`economic-group-observation/${id}`)
const insertEconomicGroupObservation = (id, observation) => http.post(`economic-group-observation/${id}`, observation)
const updateItemsInsurer = (id, items) => http.put(`companies/store-company-group/${id}/items-taker`, items)

export default {
  get,
  getByCNPJ,
  store,
  update,
  destroy,
  newCompany,
  storeCompanyGroup,
  getEconomicGroups,
  showEconomicGroup,
  deleteEconomicGroup,
  deleteCompany,
  deleteInsurer,
  updateGroup,
  updateInsurer,
  showEconomicGroupFiles,
  downloadEconomicGroupFile,
  deleteEconomicGroupFile,
  updateEconomicGroup,
  getInsurerStatus,
  downloadEconomicGroupZipFile,
  deleteEconomicGroupObservation,
  insertEconomicGroupObservation,
  updateItemsInsurer
}
